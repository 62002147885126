var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn-scroll-to-top", class: { show: _vm.y > 250 } },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(255, 255, 255, 0.15)",
              expression: "'rgba(255, 255, 255, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          staticClass: "btn-icon",
          attrs: { variant: "primary" },
          on: { click: _vm.scrollToTop }
        },
        [_c("feather-icon", { attrs: { icon: "ArrowUpIcon", size: "16" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }