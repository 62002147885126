export default {
  namespaced: true,
  state: {
    clients: {
      fetchingClientsList: true,
      fetchingClientSitesList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_CLIENTS_LIST(state, newVal) {
      state.clients.fetchingUserList = newVal
      console.log(state.clients.fetchingUserList)
    },
  },
  actions: {},
}
