export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'developer',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      resource: 'developer',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Abilities Templates ---- ---------------*
  // *===============================================---*
  {
    path: '/apps/abilitiestemplates/list',
    name: 'apps-abilities-list',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-list/AbilitiesTemplatesList.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'read',
    },
  },
  {
    path: '/apps/abilitiestemplates/add/',
    name: 'apps-abilities-add',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-add/AbilitiesTemplatesAdd.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          to: { name: 'apps-abilities-list' },
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'create',
    },
  },
  {
    path: '/apps/abilitiestemplates/edit/',
    name: 'apps-abilities-edit',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-edit/AbilitiesTemplatesEdit.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          to: { name: 'apps-abilities-list' },
        },
        {
          text: 'Edit Template',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'update',
    },
  },
  // *===============================================---*
  // *--------- Firmware Management ---- ---------------*
  // *===============================================---*
  {
    path: '/apps/firmware/list',
    name: 'apps-firmware-list',
    component: () => import('@/views/apps/firmware/firmware-list/FirmwareList.vue'),
    meta: {
      pageTitle: 'Firmware Management',
      breadcrumb: [
        {
          text: 'Firmware',
          active: true,
        },
      ],
      resource: 'firmwaremanagement',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/users/view/:username',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/users/edit/:username',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'users',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- Clients ---- ---------------------------*
  // *===============================================---*
  {
    path: '/apps/clients/list',
    name: 'apps-clients-list',
    component: () => import('@/views/apps/clients/clients-list/ClientsList.vue'),
    meta: {
      pageTitle: 'Client Account',
      breadcrumb: [
        {
          text: 'Client Account',
          active: true,
        },
      ],
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/view/:clientname',
    name: 'apps-clients-view',
    component: () => import('@/views/apps/clients/clients-view/ClientsView.vue'),
    meta: {
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/edit/:clientname',
    name: 'apps-clients-edit',
    component: () => import('@/views/apps/clients/clients-edit/ClientsEdit.vue'),
    meta: {
      resource: 'clients',
      action: 'update',
    },
  },
  {
    path: '/apps/clients/clientsites/view/:clientname/:sitename',
    name: 'apps-client-sites-view',
    component: () => import('@/views/apps/clients/clientsites-view/ClientSitesView.vue'),
    meta: {
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/clientsites/edit/:clientname/:sitename',
    name: 'apps-client-sites-edit',
    component: () => import('@/views/apps/clients/clientsites-edit/ClientSitesEdit.vue'),
    meta: {
      resource: 'clients',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- Camera Console ---- --------------------*
  // *===============================================---*
  {
    path: '/apps/cameras/list',
    name: 'apps-cameraconsole-list',
    component: () => import('@/views/apps/cameraconsole/cameraconsole-list/CameraconsoleList.vue'),
    meta: {
      pageTitle: 'Cameras',
      breadcrumb: [
        {
          text: 'Cameras',
          active: true,
        },
      ],
      resource: 'cameras',
      action: 'read',
    },
  },
  {
    path: '/apps/cameras/edit/:cameraid',
    name: 'apps-cameraconsole-edit',
    component: () => import('@/views/apps/cameraconsole/cameraconsole-cameraview/CamerasManagementEdit.vue'),
    meta: {
      pageTitle: 'Cameras',
      breadcrumb: [
        {
          text: 'Cameras',
          to: { name: 'apps-cameraconsole-list' },
        },
        {
          text: 'Camera Details',
          active: true,
        },
      ],
      resource: 'cameras',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Cameras Management ---- ----------------*
  // *===============================================---*
  {
    path: '/apps/camerasmanagement/list',
    name: 'apps-cameras-management-list',
    component: () => import('@/views/apps/cameras/cameras-management-list/CamerasManagementList.vue'),
    meta: {
      pageTitle: 'Cameras Management',
      breadcrumb: [
        {
          text: 'Cameras',
          active: true,
        },
      ],
      resource: 'camerasmanagement',
      action: 'read',
    },
  },
  {
    path: '/apps/camerasmanagement/edit/:cameraid',
    name: 'apps-cameras-management-edit',
    component: () => import('@/views/apps/cameras/cameras-management-edit/CamerasManagementEdit.vue'),
    meta: {
      pageTitle: 'Cameras Management',
      breadcrumb: [
        {
          text: 'Cameras',
          to: { name: 'apps-cameras-management-list' },
        },
        {
          text: 'Edit Camera',
          active: true,
        },
      ],
      resource: 'camerasmanagement',
      action: 'update',
    },
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      resource: 'Auth',
    },
  },
]
